define("discourse/plugins/swapd-customcomposerfields/discourse/templates/components/ccf-field", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <Input
    @class="ccf__input"
    placeholder={{this.placeholder}}
    @value={{this.value}}
    required={{this.field.required}}
    {{on "focusout" this.format}}
  />
  */
  {
    "id": "lUOEPmdW",
    "block": "[[[8,[39,0],[[16,\"placeholder\",[30,0,[\"placeholder\"]]],[16,\"required\",[30,0,[\"field\",\"required\"]]],[4,[38,1],[\"focusout\",[30,0,[\"format\"]]],null]],[[\"@class\",\"@value\"],[\"ccf__input\",[30,0,[\"value\"]]]],null]],[],false,[\"input\",\"on\"]]",
    "moduleName": "discourse/plugins/swapd-customcomposerfields/discourse/templates/components/ccf-field.hbs",
    "isStrictMode": false
  });
});