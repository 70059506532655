define("discourse/plugins/swapd-customcomposerfields/discourse/components/custom-composer-fields", ["exports", "@ember/utils", "@ember/runloop", "@ember/component"], function (_exports, _utils, _runloop, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // import { default as computed, observes } from "ember-addons/ember-computed-decorators";
  var _default = _exports.default = _component.default.extend({
    classNames: ["ccf__fields"],
    didInsertElement() {
      const saved = this.get("model.custom_composer_fields");
      if ((0, _utils.isBlank)(saved)) {
        const data = this.get("model.action") == "edit" ? this.get("model.topic.custom_composer_fields") || null : null;
        this.set("model.custom_composer_fields", data || {
          values: {},
          fields: []
        });
        if (!(0, _utils.isBlank)(data)) {
          this._saveCopy(data);
        }
      } else {
        this._saveCopy(saved);
      }
      if (!this.get("model.structured_data")) {}
      if (this.get("site.mobileView")) {
        (0, _runloop.next)(this, "_modifyParent", 750);
      } else {
        (0, _runloop.next)(this, "_setPreviewMargin", 500);
      }
    },
    willDestroyElement() {
      if (!this.get("site.mobileView")) {
        document.querySelectorAll(".d-editor-preview-wrapper").forEach(element => {
          element.style.marginTop = "";
        });
      }
    },
    _setPreviewMargin() {
      const preview = document.querySelector(".d-editor-preview-wrapper");
      const origMarginTop = parseInt(window.getComputedStyle(preview).marginTop, 10);
      const selfElement = this.element; // Assuming 'this' refers to an Ember component instance
      const selfHeight = selfElement.offsetHeight;
      preview.style.marginTop = `${origMarginTop - selfHeight}px`;
    },
    _modifyParent() {
      document.querySelectorAll(".composer-fields").forEach(element => {
        element.style.maxHeight = "40vh";
        element.style.zIndex = 1000;
        element.style.overflow = "auto";
      });
    },
    _saveCopy(copy) {
      this.keyValueStore.set({
        key: "custom_composer_fields_copy",
        value: JSON.stringify(copy)
      });
    }
  });
});