define("discourse/plugins/swapd-customcomposerfields/discourse/templates/components/custom-composer-fields", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#each this.model.categoryCustomComposerFields as |field|}}
    {{ccf-field
      value=(mut (get this.model.custom_composer_fields.values field.name))
      field=field
    }}
  {{/each}}
  */
  {
    "id": "+YkrLwco",
    "block": "[[[42,[28,[37,1],[[28,[37,1],[[30,0,[\"model\",\"categoryCustomComposerFields\"]]],null]],null],null,[[[1,\"  \"],[1,[28,[35,2],null,[[\"value\",\"field\"],[[28,[37,3],[[28,[37,4],[[30,0,[\"model\",\"custom_composer_fields\",\"values\"]],[30,1,[\"name\"]]],null]],null],[30,1]]]]],[1,\"\\n\"]],[1]],null]],[\"field\"],false,[\"each\",\"-track-array\",\"ccf-field\",\"mut\",\"get\"]]",
    "moduleName": "discourse/plugins/swapd-customcomposerfields/discourse/templates/components/custom-composer-fields.hbs",
    "isStrictMode": false
  });
});