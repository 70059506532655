define("discourse/plugins/swapd-customcomposerfields/discourse/templates/connectors/category-custom-settings/custom-composer-fields-setting", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.siteSettings.custom_composer_fields_enabled}}
    <div class="field">
      <label class="checkbox-label">
        {{input
          type="checkbox"
          checked=this.category.custom_fields.custom_composer_fields_enabled
        }}
  
        {{i18n "custom_composer_fields.category_setting.checkbox_label"}}
      </label>
    </div>
  
    {{#if this.category.custom_fields.custom_composer_fields_enabled}}
      <div class="field">
        <label style="font-weight:bolder">
          {{i18n "custom_composer_fields.category_setting.textarea_label"}}
        </label>
  
        {{textarea
          value=this.category.custom_fields.custom_composer_fields
        }}
      </div>
  
      <div class="field">
        <label class="checkbox-label">
          {{input
            type="checkbox"
            checked=this.category.custom_fields.ccf_require_images
          }}
  
          {{i18n "custom_composer_fields.category_setting.require_images_label"}}
  
        </label>
      </div>
    {{/if}}
  {{/if}}
  
  */
  {
    "id": "X1QC6IMV",
    "block": "[[[41,[30,0,[\"siteSettings\",\"custom_composer_fields_enabled\"]],[[[1,\"  \"],[10,0],[14,0,\"field\"],[12],[1,\"\\n    \"],[10,\"label\"],[14,0,\"checkbox-label\"],[12],[1,\"\\n      \"],[1,[28,[35,3],null,[[\"type\",\"checked\"],[\"checkbox\",[30,0,[\"category\",\"custom_fields\",\"custom_composer_fields_enabled\"]]]]]],[1,\"\\n\\n      \"],[1,[28,[35,4],[\"custom_composer_fields.category_setting.checkbox_label\"],null]],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\\n\"],[41,[30,0,[\"category\",\"custom_fields\",\"custom_composer_fields_enabled\"]],[[[1,\"    \"],[10,0],[14,0,\"field\"],[12],[1,\"\\n      \"],[10,\"label\"],[14,5,\"font-weight:bolder\"],[12],[1,\"\\n        \"],[1,[28,[35,4],[\"custom_composer_fields.category_setting.textarea_label\"],null]],[1,\"\\n      \"],[13],[1,\"\\n\\n      \"],[1,[28,[35,5],null,[[\"value\"],[[30,0,[\"category\",\"custom_fields\",\"custom_composer_fields\"]]]]]],[1,\"\\n    \"],[13],[1,\"\\n\\n    \"],[10,0],[14,0,\"field\"],[12],[1,\"\\n      \"],[10,\"label\"],[14,0,\"checkbox-label\"],[12],[1,\"\\n        \"],[1,[28,[35,3],null,[[\"type\",\"checked\"],[\"checkbox\",[30,0,[\"category\",\"custom_fields\",\"ccf_require_images\"]]]]]],[1,\"\\n\\n        \"],[1,[28,[35,4],[\"custom_composer_fields.category_setting.require_images_label\"],null]],[1,\"\\n\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null]],[]],null]],[],false,[\"if\",\"div\",\"label\",\"input\",\"i18n\",\"textarea\"]]",
    "moduleName": "discourse/plugins/swapd-customcomposerfields/discourse/templates/connectors/category-custom-settings/custom-composer-fields-setting.hbs",
    "isStrictMode": false
  });
});