define("discourse/plugins/swapd-customcomposerfields/discourse/templates/connectors/composer-fields/custom-composer-fields-conn", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.siteSettings.custom_composer_fields_enabled}}
    {{#if this.model.categoryCustomComposerFields}}
      {{custom-composer-fields model=this.model}}
    {{/if}}
  {{/if}}
  */
  {
    "id": "5r0cgkW3",
    "block": "[[[41,[30,0,[\"siteSettings\",\"custom_composer_fields_enabled\"]],[[[41,[30,0,[\"model\",\"categoryCustomComposerFields\"]],[[[1,\"    \"],[1,[28,[35,1],null,[[\"model\"],[[30,0,[\"model\"]]]]]],[1,\"\\n\"]],[]],null]],[]],null]],[],false,[\"if\",\"custom-composer-fields\"]]",
    "moduleName": "discourse/plugins/swapd-customcomposerfields/discourse/templates/connectors/composer-fields/custom-composer-fields-conn.hbs",
    "isStrictMode": false
  });
});